<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Archived Products</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Archived Products"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-btn depressed light :to="{ name: 'module-eow-products' }">
              <v-icon small left>mdi-arrow-left</v-icon> Back to Products
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="tableHeaders"
            :items="products"
            no-data-text="No Archived Products found"
          >
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "eow",
          disabled: false,
          to: { name: "app-dashboard", params: { id: this.$route.params.id } },
        },
        {
          text: "Products",
          disabled: false,
          exact: true,
          to: { name: "module-eow-products" },
        },
        {
          text: "Archived",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Name", value: "name" },
        { text: "Slug", value: "slug" },
      ],
    };
  },

  computed: {
    products() {
      let products = this.$store.state.eow.products["archived"];

      if (this.searchTerm !== "") {
        products = products.filter((p) => {
          const name = p.name.toLowerCase();
          const slug = p.slug.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();
          return name.includes(searchTerm) || slug.includes(searchTerm);
        });
      }

      return products;
    },
  },
};
</script>
